<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      style="fill:#FFDC64;"
      d="M477.288,238.644H373.153v121.492h130.169c4.792,0,8.678-3.886,8.678-8.678v-78.102
	C512,254.185,496.459,238.644,477.288,238.644z"
    />
    <path
      style="fill:#FFC850;"
      d="M373.153,256h104.136c9.586,0,17.356,7.77,17.356,17.356v86.78H373.153V256z"
    />
    <path
      style="fill:#E1A546;"
      d="M493.954,360.136c-4.221-29.427-29.462-52.068-60.056-52.068s-55.835,22.641-60.056,52.068H493.954z"
    />
    <path
      style="fill:#FFDC64;"
      d="M442.576,134.508h-69.424v34.712h91.119c2.396,0,4.339-1.943,4.339-4.339v-4.339
	C468.61,146.164,456.955,134.508,442.576,134.508z"
    />
    <path
      style="fill:#B4E66E;"
      d="M364.475,99.797H52.068c-4.792,0-8.678,3.886-8.678,8.678v173.559H26.034
	c-4.797,0-8.678,3.886-8.678,8.678c0,4.792,3.881,8.678,8.678,8.678h52.068c4.797,0,8.678,3.886,8.678,8.678
	c0,4.792-3.881,8.678-8.678,8.678H43.39v34.712c0,4.792,3.886,8.678,8.678,8.678h321.085V108.475
	C373.153,103.682,369.267,99.797,364.475,99.797z"
    />
    <path
      style="fill:#A0D755;"
      d="M178.501,316.746c-9.087-5.427-19.619-8.678-30.976-8.678c-11.357,0-21.889,3.251-30.976,8.678H8.678
	c-4.797,0-8.678,3.886-8.678,8.678c0,4.792,3.881,8.678,8.678,8.678H43.39v17.356c0,4.792,3.886,8.678,8.678,8.678h321.085v-43.39
	H178.501z"
    />
    <g>
      <circle style="fill:#5B5D6E;" cx="433.898" cy="368.814" r="43.39" />
      <circle style="fill:#5B5D6E;" cx="147.525" cy="368.814" r="43.39" />
    </g>
    <polygon
      style="fill:#B4E6FF;"
      points="477.288,238.644 459.932,169.22 373.153,169.22 373.153,238.644 "
    />
    <circle style="fill:#C7CFE2;" cx="147.525" cy="368.814" r="26.034" />
    <circle style="fill:#7F8499;" cx="147.525" cy="368.814" r="8.678" />
    <circle style="fill:#C7CFE2;" cx="433.898" cy="368.814" r="26.034" />
    <circle style="fill:#7F8499;" cx="433.898" cy="368.814" r="8.678" />
    <path
      style="fill:#9BD6FF;"
      d="M407.864,186.576h56.407l-4.339-17.356h-86.78v69.424h26.034v-43.39
	C399.186,190.462,403.072,186.576,407.864,186.576z"
    />
    <path
      style="fill:#FFC850;"
      d="M373.153,151.864h69.424c4.792,0,8.678,3.886,8.678,8.678v8.678h-78.102V151.864z"
    />
    <rect
      x="485.966"
      y="273.356"
      style="fill:#F1F4FB;"
      width="26.034"
      height="34.712"
    />
    <path
      style="fill:#FF8C66;"
      d="M468.61,273.356v17.356c0,9.585,7.771,17.356,17.356,17.356h8.678v-34.712H468.61z"
    />
    <path
      style="fill:#FFFFFF;"
      d="M199.593,266.847c-2.78,0-5.551-1.06-7.669-3.178l-52.068-52.068c-4.237-4.237-4.237-11.101,0-15.339
	c4.237-4.238,11.101-4.237,15.339,0l44.399,44.399l79.11-79.11c4.237-4.237,11.101-4.237,15.339,0
	c4.238,4.237,4.237,11.101,0,15.339l-86.78,86.78C205.144,265.788,202.373,266.847,199.593,266.847z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
